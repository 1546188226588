<template>
  <el-container class="patroladd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item>系统配置</el-breadcrumb-item>
         <el-breadcrumb-item>系统配置列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.config_id">编辑系统配置</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增系统配置</el-breadcrumb-item>
          </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="">
      <el-form :inline="true" status-icon ref="form" :model="form" class="demo-ruleForm">
        <el-row class="box mt20 f14">
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="系统配置名称："
              size="medium"
              prop="config_name"
              :rules="[{required: true,message: '必填项', trigger: 'blur' }]"
            >
              <el-input
                placeholder="系统配置名称"
                v-model="form.config_name"
                class="input-with-select"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="22" class="tl" >
            <el-row class="box mt20 f14">
              <el-form-item class="w" label-width="14%" label="区域：" size="medium">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="provinceTemp"
                      placeholder="请选择省级"
                      @change="handleChange($event,2)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in provinceOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="cityTemp"
                      placeholder="请选择市级"
                      @change="handleChange($event,3)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in cityOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="areaTemp"
                      placeholder="请选择区/县"
                      @change="handleChange($event,4)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in areaOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="townTemp"
                      placeholder="请选择街道/镇"
                      @change="handleChange($event,5)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in townOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="villTemp"
                      placeholder="请选择居委会/村"
                      value-key="region_name"
                      @change="handleChange($event,6)"
                    >
                      <el-option
                        v-for="item in villOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
            <el-col :span="11" class="">
            <el-form-item  class="rules w" size="medium" label-width="28%" label="配置状态："  >
              <el-select  v-model="form.config_state" clearable placeholder="请选择配置状态">
                <el-option
                  v-for="item in Options2"
                  :key="item.value" :label="item.label" :value="String(item.value)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl" :offset="1">
           <el-button class size="medium" type="primary" @click="submitForm('form')">提交</el-button>
        </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../../util/api";
import { local,session } from "../../../../util/util";

export default {
  data() {
    return {
      form: {
        config_id: "",
        config_name: "",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        config_state:'2'
      },
      config: {},
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      userData: {},
      Options2:[{ label: "开启", value: "1" },{ label: "关闭", value: "2" }],
    };
  },
  async mounted() {
      this.form.config_id =session.get('params').id?String(session.get('params').id):'';
     this.userData.user_type ='1';
    await this.init({ config_id:this.form.config_id});
  },
  methods: {
    init(params) {
         if(this.form.config_id){
          axios.get("/pc/config/single", params).then(response => {
           this.config = response.data.config;
           this.form.config_id = response.data.config.config_id;
           this.form.config_state=String(response.data.config.config_state);
            this.form.config_name = response.data.config.config_name;
            this.handleUserLevel(this.userData, this.config);
      });
    
       }else{
       this.handleUserLevel(this.userData,'');}
      
    },
    async handleUserLevel(userData, config) {
      if (parseInt(userData.user_type) > 0 || config.province) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item =>item.region_name ==(config.province ? config.province :userData.user_province)
        //    item =>item.region_name ==(userData.user_province ?userData.user_province :company.province  )
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 || config.province) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            item =>
              item.region_name ==
              (userData.user_city ? userData.user_city : config.city)
          );
          if (parseInt(userData.user_type) >= 2 || config.city) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item =>
                item.region_name ==
                (userData.user_area ? userData.user_area : config.area)
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 3 || config.area) {
              this.townOptions = await this.fetchRegionData(
                4,
                area.region_code
              );
              let town = this.townOptions.find(
                item =>
                  item.region_name ==
                  (userData.user_town ? userData.user_town : config.town)
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 4 || config.town) {
                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  item =>
                    item.region_name ==
                    (userData.user_vill ? userData.user_vill : config.vill)
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 5 || config.vill) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = ''
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
      }
    },
    submitForm() {
      this.form.province = this.provinceTemp?this.provinceTemp.region_name:'';
      this.form.city = this.cityTemp?this.cityTemp.region_name:'';
      this.form.area = this.areaTemp?this.areaTemp.region_name:'';
      this.form.town = this.townTemp?this.townTemp.region_name:'';
      this.form.vill = this.villTemp?this.villTemp.region_name:'';
       let params={
           config_id:String(this.form.config_id),
           config_name:this.form.config_name,
           province:this.form.province,
           city:this.form.city,
           area:this.form.area,
           town:this.form.town,
           vill:this.form.vill,
           config_state:this.form.config_state
       }
        if(this.form.config_id){
          axios.put("/pc/config/update", this.form).then(v => {
          this.$router.back();
        });
       }else{
          axios.post("/pc/config/save", this.form).then(v => {
          this.$router.back();
        });
       }
    }
  }
};
</script>
<style lang="scss" scoped>
.patroladd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /* .el-form-item--medium .el-form-item__content{width: 80%} */
}
</style>
